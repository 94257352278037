import { createApp } from 'vue'
import App from './App.vue'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/notify/Notify.js';
import quasarUserOptions from './quasar-user-options'

const app = createApp(App).use(Quasar, quasarUserOptions);

app.use(Quasar, {
  plugins: {
    Notify
  }, quasarUserOptions
});

app.use(VCalendar, {});
app.mount('#app');

